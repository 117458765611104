<template>
  <div class="test">
    <br>
    <br>
    <base-section-heading title="welcome">
      <p>
        Mulligan Hospitality group founded in 2019, currently owns & operates seven  spectacular Restaurant/Bars & hotels across Ireland’s capital.
      </p>
      <br>
      <base-btn
        :block="$vuetify.breakpoint.smAndDown"
        to="/work-with-us"
        outlined
        class="elevation-3 BUTN"
        data-aos="flip-up"
      >
        WORK WITH US
      </base-btn>
    </base-section-heading>
    <br>
  </div>
</template>

<style scoped>

.test {
  padding: 10px 10px 10px 10px;
}

.BUTN {
 margin: auto;
}
</style>

<script>
  export default {
    name: 'Welcome',
  }
</script>
